html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

/*@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}*/

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'react-toastify/dist/ReactToastify.css';
ul[role='navigation'] {
  margin: 20px 0;
  margin: auto;
  text-align: center;
}
ul[role='navigation'] li {
  display: inline-block;
  padding: 10px;
  text-align: center;
}

ul[role='navigation'] li.disabled a {
  color: #ccc
}

ul[role='navigation'] li.selected {
  text-decoration: underline;
}

.StripeElement {
  width: 100%
}
.fa-spin {
  animation: fa-spin 2s infinite linear;
}
@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
ul[role=navigation] {
  margin-top: 5px;
}
ul[role=navigation] li.selected {
  font-weight: bold;
  background: #eee;
  text-decoration: none;

}
ul[role=navigation] li.selected a {

}